/**
 * 已登录的用户信息[账号,令牌,请求头密匙,版本号,等..]
 */
import CookieLib from "@/utils/Cache/common/CookieLib";

const field = {
    username:'user-username',
    token:'user-token',
    auth:'user-auth',
    version:'user-version',
};
const Lib = {

    /**
     * 保存用户信息[账号,令牌,请求头密匙]
     * @param username
     * @param token
     * @param auth
     */
    setUserInfo(username,token,auth){
        CookieLib.SetCookie(field.username,username);
        CookieLib.SetCookie(field.token,token);
        CookieLib.SetCookie(field.auth,auth);
    },
    /**
     * 设置当前版本
     * @param version
     */
    setVersion(version){
        CookieLib.SetCookie(field.version,Number(version),7);
    },
    /**
     * 获取用户全部信息
     * @return {{}}
     * 返回对象,包含[账号,令牌,请求头密匙,版本号,等..]
     */
    getUserInfo(){
        let userInfo = {};
        for (let fieldKey in field) {
            let value = CookieLib.getCookie(field[fieldKey]);
            userInfo[fieldKey] = fieldKey == 'version' ? Number(CookieLib.getCookie(field[fieldKey])) : value;
        }
        return userInfo;
    }
};
export default Lib;