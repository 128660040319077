const langs = {
    //获取用户信息
    getUserData:{
        1:'用户不存在',
        2:'用户所属代理商与登录代理商不一致',
        3:'userId 不正确',
    },

    //修改用户信息
    setUserInfo:{
        1:'字段异常',
        2:'字段数据不符合规则',
        3:'userId异常',
        4:'用户不存在',
        5:'用户所属代理商不是您',
        6:'指向异常',
    },

    //回收用户
    batchRecovery:{
        1:'解析用户列表异常',
        2:'超过数量限制',
    },
    //[回收用户]'state'错误代码解析
    batchRecovery_state:{
        100:'执行成功',
        1:'用户不存在',
        2:'用户超过回收期限[切割超过7天]',
        3:'查找使用的卡密失败',
        4:'重置卡密失败',
        5:'执行异常[*]',
    },

    //迁移用户
    MoveUser:{
        1:'部分用户[不存在/所属不是您]',
        2:'新代理商不存在',
        3:'执行异常[*3]',
        4:'执行异常[*4]',
        5:'迁移数量超过数量限制',
    },

    //批量充值
    batchUserMoney:{
        1:'充值列表异常',
        2:'部分用户不符合规则[不存在/所属代理商不正确]',
        3:'超过数量限制',
        4:'代理商余额不足',
        5:'执行异常[5]',
        6:'执行异常[6]',
    },

    //[批量注册日志]下载日志
    getFile:{
        1:'查询日志异常',
    },
    
    //批量注册
    userBulkRegister:{
        1:'注册码不足',
        2:'执行异常[2]',
        3:'执行异常[3]',
        4:'部分账号已存在',
    },

    //[充值/回收]用户余额
    changeMoney:{
        1:'用户不存在',
        2:'查询代理商数据异常',
        3:'用户所属代理商不正确',
        4:'[代理商]余额不足',
        5:'[用户]余额不足',
        6:'执行异常[6]',
        7:'提交参数不符合规则]',
        8:'当前用户未使用,禁止操作',
    }
};
export default langs;