import LangsUtil from "@/utils/Lang/LangsUtil";

// zh
import zh_common from './zh/Common';
import zh_user from './zh/User';
import zh_key from './zh/Key';
import zh_agent from './zh/Agent'
//en
import en_common from './en/Common';
import en_user from './en/User';
import en_key from './en/Key';
import en_agent from './en/Agent'


const ApisLangs = {
    zh:{
        Common:zh_common,
        User:zh_user,
        Key:zh_key,
        Agent:zh_agent,
    },
    en:{
        Common:en_common,
        User:en_user,
        Key:en_key,
        Agent:en_agent,
    }
};
const getApisLang = (Module,ApiName) => {
    let res = LangsUtil(ApisLangs,Module);
    return res[ApiName] ?? {};
}
export default getApisLang;