const Agent = {
    //添加下级代理
    addAgent:{
        1:'不符合规则',
        2:'账号已存在',
        3:'执行异常[3]',
    },

    //修改代理信息
    setAgentInfo:{
        1:'提交数据字段异常',
        2:'提交数据不符合规则',
        3:'查询异常',
        4:'代理商不存在',
        5:'所属上级异常',
        6:'执行异常[6]',
    },

    //[下级代理商][增加/减少]余额
    changeMoney:{
        1:'提交数据异常',
        2:'查询数据异常',
        3:'代理不存在',
        4:'余额不足',
        5:'执行异常[5]',
        6:'不是您的下级',
    },
    //代理日志类型[type]
    getAgentLog_type:{
        1:'新增下级代理商',
        2:'修改下级代理信息',
        3:'修改自己密码',
        4:'充值下级余额[注册/充值]',
        5:'回收下级余额[注册/充值]',
        6:'上级充值余额[注册/充值]',
        7:'上级回收余额[注册/充值]',
        8:'迁移卡密',
        9:'回迁卡密',
        10:'批量生成卡密',
        11:'回收卡密',
        12:'[用户余额]充值',
        13:'[用户余额]回收',
        14:'批量充值用户余额',
        15:'[单个/批量]回收用户',
        16:'[单个/批量]注册用户',
        17:'修改用户信息',
        18:'用户所属代理商变更[迁移用户/回收用户]',
        un:'*?未知日志类型[请联系客服人员]',
        //易语言日志类型[大于100的]
        100:'新增下级代理商',
        101:'总代发放',
        102:'授权用户次数',
        103:'分配帐号额度',
        104:'增加帐号额度',
        105:'生成注册码',
        106:'生成次数码',
        107:'分配额度帐号',
        108:'余额转码[注册/充值]码 ',
        109:'增加次数额度',
        110:'分配额度次数天数',
        111:'余额转码次数天数充值码',
        112:'迁移用户',
        113:'[注册码]回迁卡密',
        114:'[注册码] [新增]回迁卡密',
        115:'回迁用户',
        116:'注册码下拨卡密',
        117:'充值码下拨卡密',
        118:'[充值码]回迁卡密',
        119:'充值码新增回迁卡密数量',
        120:'用户充值',
        121:'回收下级额度',
        122:'上级回收额度',
        123:'回收自己卡密',
        124:'回收用户余额',
        125:'批量充值用户',
    },
    //下级代理用户.回迁用户
    moveUserByLowAgent:{
        1:'部分用户不存在 / 部分用户所属权不正确',
        2:'代理商不存在',
        3:'执行异常[3]',
        4:'执行失败[4]',
    }
}
export default Agent