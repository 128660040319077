const langs = {
    //生成卡密
    makeKey:{
        1:'代理账号不存在',
        2:'余额不足',
        3:'执行异常[3]',
        4:'提交参数不符合规则',
    },

    //批量迁移卡密[自己->下级代理] 和 批量回迁卡密[下级代理商->自己]  (这两个接口共用一个语言包)
    batchMoveKey:{
        1:'卡密列表异常',
        2:'超过最大数量限制[20]',
        3:'新代理商账号不存在',
    },
    batchMoveKey_state:{
        100:'执行成功',
        1:'卡密不存在',
        2:'卡密所属代理商不正确',
        3:'执行异常[3]',
    },

    //回收卡密[自己卡密]
    batchRecoveryKey:{
        1:'卡密列表异常',
        2:'超过最大数量限制[20]',
    },
    batchRecoveryKey_state:{
        100:'执行成功',
        1:'卡密不存在',
        2:'查询代理商异常',
        3:'卡密已使用',
        4:'卡密所属状态异常',
        5:'执行异常[5]',
    },
};
export default langs;