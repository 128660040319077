import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "main",
  style: {
    "margin-top": "-20vh"
  }
};
export function render(_ctx, _cache) {
  const _component_van_loading = _resolveComponent("van-loading");
  const _component_van_overlay = _resolveComponent("van-overlay");
  return _openBlock(), _createBlock(_component_van_overlay, {
    show: ""
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_loading, {
      size: "24px",
      color: "#1989fa"
    }, {
      default: _withCtx(() => [_createTextVNode(" Loading... ")]),
      _: 1
    })])]),
    _: 1
  });
}