import axios from 'axios'
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import DialogLoading from "@/utils/Components/DialogLoading";


// 创建一个 request 实例
const service = axios.create({
    // baseURL: 'http://linux.com/', // 所有的请求地址前缀部分
    // baseURL: 'http://api.x--9.com/', // 所有的请求地址前缀部分
    // baseURL: 'https://httpsapi.x--9.com/', // 所有的请求地址前缀部分
    // baseURL: 'http://php.x--0.com/', // 新思睿主服务器请求
    baseURL: 'https://console.x--0.com/', // 新思睿主服务器请求[https]

    timeout: 60000, // 请求超时时间毫秒
    // withCredentials: true, // 异步请求携带cookie
    headers: {
        // 设置后端需要的传参类型
        'Content-Type': 'application/json',
        // 'token':'123',
    },
})

// 添加请求拦截器
service.interceptors.request.use(
    function (config) {
        // 在发送请求之前做些什么
        // Toast.loading({
        //     message:'Sending request...',
        //     forbidClick: true,
        //     loadingType: 'spinner',
        //     duration:1000,
        // });
        if (config.url !== 'AgentApi/Api.Auth/checkToken'){
            VantMsgLib.notify(1,'Sending request...',1000);
            DialogLoading.open();
        }
        return config;
    },
    function (error) {
        // 对请求错误做些什么
        console.log('request-error',error)
        return Promise.reject(error)
    }
)

// 添加响应拦截器
service.interceptors.response.use(
    function (response) {
        DialogLoading.close();
        // console.log('response',response)
        // 2xx 范围内的状态码都会触发该函数。
        // 对响应数据做点什么
        // dataAxios 是 request 返回数据中的 data
        const dataAxios = response.data;
        // 这个状态码是和后端约定的
        // const code = dataAxios.code
        // console.log('dataAxios->code',code,dataAxios)
        return dataAxios;
    },
    function (error) {
        // 超出 2xx 范围的状态码都会触发该函数。
        // 对响应错误做点什么
        console.log('[response]error',error);
        VantMsgLib.alert(
            'Server communication error\nMsg:' + error.message,
            `Error Code:${error.code}`
        );
        return Promise.reject(error);
    }
)

export default service;