import DialogLoadingVue from '@/components/Dialog/DialogLoading';
import CreateComponents from "@/utils/Components/CreateComponents";

let helper;
let isOpen = false;
const lib = {
    open(){
        helper = new CreateComponents(DialogLoadingVue);
        isOpen = true;
    },
    close(){
        if (isOpen){
            helper.unmount();
            isOpen = false;
        }
    }
};
export default lib;