import LangBase from "@/utils/Lang/common/LangBase";

const getLang = (langs,module) => {
    let res = {};
    let lib = new LangBase();
    let nowLang = lib.getLanguage();
    if (langs[nowLang] && langs[nowLang][module]){
        res = langs[nowLang][module];
    }
    return res;
}
export default getLang;