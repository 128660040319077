/**
 * [Js调用Vue组件]
 * 创建Vue组件,并挂载在当前页面上
 */
import {createApp} from "vue";

class CreateComponents {
    /**
     * 创建组件并挂载
     * @param VueComponents
     */
    constructor(VueComponents) {
        this.components = createApp(VueComponents);
        // 创建一个挂载容器
        this.parentNode = document.createElement('div')
        document.body.appendChild(this.parentNode)
        // 挂载组件
        this.components.mount(this.parentNode)
    }
    /**
     * 卸载组件
     */
    unmount(){
        this.components.unmount()
        document.body.removeChild(this.parentNode)
    }
}
export default CreateComponents;