/**
 * 封装好的axios,可直接调用
 */
import httpRequest from '@/apis/Axios/HttpRequest';
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import CacheUserInfo from "@/utils/Cache/CacheUserInfo";

const http = {
    /**
     * [axios]发送post请求
     * @param url
     * @param params 请求参数
     * @param checkCode 是否检查返回状态码,不符合100弹出错误信息框
     * @param sucessAlert 调用成功是否弹出信息框
     * @param fieldCodeLang [不填写该参数则不弹出]错误代码的文本消息[用于提示用户失败原因]
     * @return {*}
     */
    post(url,params,checkCode = false,sucessAlert = false,fieldCodeLang = {}){
        let userInfo = CacheUserInfo.getUserInfo();
        let obj = new httpRequest({
            url: url,
            method: 'post',
            data: {
                ...params,
                version:userInfo.version,
            },
            headers:{
                authUser:userInfo.auth,
            },
        });
        obj.then(res=>{
            let bool = this.checkResCode(res);
            if (bool){
                if (sucessAlert){
                    VantMsgLib.alertSuccess();
                }
            }else{
                //失败
                let code = res.code ?? -1;
                if (fieldCodeLang[Number(code)]){
                    VantMsgLib.alert('Error Message:' + fieldCodeLang[Number(code)],`Error Code:${code}`);
                }else if (checkCode){
                    let msg = res.msg ?? 'unknown error';
                    VantMsgLib.alert('*Error Message:' + msg,`*Error Code:${code}`);
                }
            }

        });
        return obj;
    },

    /**
     * 基于返回的状态码检查Api是否调用成功
     * @param res
     * @return {boolean}
     * 成功返回true
     */
    checkResCode(res){
        if (res.code != null){
            if (res.code === 100){
                return true;
            }
        }
        return false;
    },

    /**
     * 获取Res[code]字段
     * @param res
     * @return {number|number}
     * 返回整数
     */
    getResCode(res){
        return res.code == null ? -1 : Number(res.code);
    },
    /**
     * 获取Res指定字段数据
     * @param res
     * @param field 字段名
     * @return {string|*}
     */
    getResText(res,field){
        return res[field] != null ? res[field] : 'Null';
    },
};

export default http;